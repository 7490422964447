import Vue from 'vue/dist/vue.esm'
import axios from 'axios'

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

const APP_URL = document.querySelector('meta[name="app-url"]').getAttribute('content');
const AD_TEXT = document.querySelector('meta[name="ad-assets"]').getAttribute('content').split(",")[0];
const AD_URL = document.querySelector('meta[name="ad-assets"]').getAttribute('content').split(",")[1];
const AD_IMG_SRC = document.querySelector('meta[name="ad-assets"]').getAttribute('content').split(",")[2];

document.addEventListener('DOMContentLoaded', () => {
  const jobs = new Vue({
    el: '#jobs',
    data: {
      jobs: [],
      currentJob: {},
      currentJobIsExist: false,
      jobsWasLoaded: false,
      page: 1,
      totalPages: 1,
      totalJobs: 0,
      searchAttributes: {
        kind: [],
        level: [],
        work_remote: [],
      },
      searchQuery: "",
      searchWord: "",
      searchKind: "",
      searchLevel: "",
      searchWorkRemote: "",
      showPrev: false,
      showNext: true,
      toggleEmptyResult: false,
      ad_img_src: AD_IMG_SRC,
      ad_text: AD_TEXT,
      ad_url: AD_URL,
      ad: false,
    },
    mounted() {
      this.search()
    },
    methods: {
      search: function(attribute=null, condition=null) {
        if (attribute && condition) {
          let index = this.searchAttributes[attribute].indexOf(condition);
          if (index === -1) {
            this.searchAttributes[attribute].push(condition);
          } else {
            this.searchAttributes[attribute].splice(index, 1);
          }
        }
        this.generateSearchQuery();
        axios.get(`${APP_URL}/jobs/search?${this.searchQuery}`).then(response => {
          this.jobs = response.data.jobs;
          this.searchKind = response.data.kind;
          this.searchLevel = response.data.level;
          this.searchWorkRemote = response.data.work_remote;
          this.totalPages = response.data.total_pages;
          this.totalJobs = response.data.total_jobs;
          this.jobsWasLoaded = true;
          this.togglePager();
          if (this.jobs[0]) {
            this.toggleEmptyResult = false;
            if (this.ad_text !== undefined && this.ad_url !== undefined) {
              this.ad = true;
            }
            if (this.ad === false) {
              this.show(this.jobs[0].id, false);
            }
          } else {
            this.toggleEmptyResult = true;
          }
        });
      },
      generateSearchQuery: function() {
        let temporarySearchAttributes = this.searchAttributes;
        let temporarySearchQuery = "";
        ["kind", "level", "work_remote"].forEach(function(element) {
          if (temporarySearchAttributes[element]) {
            temporarySearchAttributes[element].forEach(function(value) {
              if (temporarySearchQuery.length == 0) {
                temporarySearchQuery += `${element}[]=${value}`;
              } else {
                temporarySearchQuery += `&${element}[]=${value}`;
              }
            });
          }
        });
        this.searchQuery = temporarySearchQuery;
      },
      show: function(jobId, isTransist=false) {
        if ((window.innerWidth <= 959) && isTransist) {
          window.location.href = `${APP_URL}/jobs/${jobId}`;
        } else {
          axios.get(`${APP_URL}/jobs/${jobId}?format=json`).then(response => {
            this.currentJob = response.data.job;
            this.currentJobIsExist = true;
          });
        }
      },
      movePage: function(position=null) {
        this.togglePager(position);
        let url = `${APP_URL}/jobs/search?${this.searchQuery}&page=${this.page}`;
        if (this.searchQuery.length === 0) url = `${APP_URL}/jobs/search?page=${this.page}`;
        if (this.searchWord.length !== 0) url = url + `&word=${this.searchWord}`;
        axios.get(url).then(response => {
          this.jobs = response.data.jobs;
          this.searchKind = response.data.kind;
          this.searchLevel = response.data.level;
          this.searchWorkRemote = response.data.work_remote;
          this.totalPages = response.data.total_pages;
          this.totalJobs = response.data.total_jobs;
          this.jobsWasLoaded = true;
          if (this.jobs[0]) {
            this.toggleEmptyResult = false;
            this.show(this.jobs[0].id);
          } else {
            this.toggleEmptyResult = true;
          }
        });
      },
      searchWithWord: function() {
        let url = `${APP_URL}/jobs/search?${this.searchQuery}&page=${this.page}`;
        if (this.searchQuery.length === 0) url = `${APP_URL}/jobs/search?page=${this.page}`;
        if (this.searchWord.length !== 0) url = url + `&word=${this.searchWord}`;
        axios.get(url).then(response => {
          this.jobs = response.data.jobs;
          this.searchKind = response.data.kind;
          this.searchLevel = response.data.level;
          this.searchWorkRemote = response.data.work_remote;
          this.totalPages = response.data.total_pages;
          this.totalJobs = response.data.total_jobs;
          this.jobsWasLoaded = true;
          this.togglePager();
          if (this.jobs[0]) {
            this.toggleEmptyResult = false;
            this.show(this.jobs[0].id);
          } else {
            this.toggleEmptyResult = true;
          }
        });
      },
      adJump: function() {
        window.open(this.ad_url, "_blank");
      },
      writeApplyClicking: function(jobId=null) {
        if (jobId) {
          let url = `${APP_URL}/jobs/write_apply_clicking?job_id=${jobId}`;
          axios.post(url);
        }
      },
      togglePager: function(position=null) {
        if (this.totalPages === 1) {
          this.showPrev = false;
          this.showNext = false;
        } else {
          switch (position) {
            case 'prev':
              if (this.page === 1) {
                this.page = 1;
                this.showPrev = false;
                this.showNext = true;
              } else {
                this.page = this.page - 1;
                if (this.page === 1) {
                  this.showPrev = false;
                  this.showNext = true;
                } else {
                  this.showPrev = true;
                  this.showNext = true;
                }
              }
              break;
            case 'next':
              if (this.page + 1 >= this.totalPages) {
                this.page = this.totalPages;
                this.showPrev = true;
                this.showNext = false;
              } else {
                this.page = this.page + 1;
                if (this.page >= this.totalPages) {
                  this.showPrev = true;
                  this.showNext = false;
                } else {
                  this.showPrev = true;
                  this.showNext = true;
                }
              }
              break;
            default:
              this.page = 1;
              this.showPrev = false;
              if (this.totalPages > 1) {
                this.showNext = true;
              } else {
                this.showNext = true;
              }
              break;
          }
        }
      },
      escapeUrl: function(value="") {
        if (value === "") return "https://";
        if (value.includes("javascript")) {
          value = "https://";
        }
        if (!value.startsWith("http")) {
          value = "https://" + value;
        }
        return value;
      },
    },
    computed: {
    }
  });
});